<template>
  <div>
    <!-- Unmapped AU table -->
    <unmapped-ad-unit-tables v-if="store.mmData" :toggleCreateBE="toggleCreateBE" :toggleEditBE="toggleEditBE" :setEditValue="setEditValue" ></unmapped-ad-unit-tables>

    <!-- create BE modal -->
    <modal :show.sync="modals.createBE"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Billing Entity</h3>
      <create-billing-entity-forms class="mm-modal mm-create" :toggleCreateBE="toggleCreateBE" ></create-billing-entity-forms>
    </modal>

    <!-- edit BE modal -->
    <modal :show.sync="modals.editBE"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Billing Entity</h3>
      <edit-billing-entity-forms class="mm-modal mm-create" :toggleEditBE="toggleEditBE" :editValue="editValue" ></edit-billing-entity-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import UnmappedAdUnitTables from 'src/components/Dashboard/Views/Tables/UnmappedAdUnitTables.vue'
  import CreateBillingEntityForms from 'src/components/Dashboard/Views/Forms/CreateBillingEntityForms.vue'
  import EditBillingEntityForms from 'src/components/Dashboard/Views/Forms/EditBillingEntityForms.vue'

  export default {
    components: {
      UnmappedAdUnitTables,
      CreateBillingEntityForms,
      EditBillingEntityForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createBE: false,
          editBE: false,
        },
        editValue: {
          be_name: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        this.editValue = obj
      },
      toggleCreateBE() {
        this.modals.createBE = !this.modals.createBE
      },
      toggleEditBE() {
        this.modals.editBE = !this.modals.editBE
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
