<template>
  <div v-if="!store.mmData.user.display_tc" class="row">
    <div class="col-md-12">
      <h4 class="title">Unmapped Ad Unit List</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          utilities table
          <button id="exportMM" class="btn btn-badge btn-round btn-warning pull-right" @click="createMap">
            map units <i class="nc-icon nc-simple-add"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-mm">
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Rows" v-if="store.mmData">
              <el-select
                class="select-default mm-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-8">
          <!-- empty -->
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm search-mm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">


            <el-table-column v-for="column in tableColumns"
                             sortable
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :label="column.label">

              <!-- Site Name -->
              <template v-if="column.prop === 'sn_id'" #default="{ row }">
                <el-select class="select-primary"
                           size="large"
                           v-model="row.sn_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.site_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </template>

              <!-- Device Type -->
              <template v-else-if="column.prop === 'device_type_id'" #default="{ row }">
                <el-select class="select-primary"
                           size="large"
                           v-model="row.device_type_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.device_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </template>

              <!-- Product Subtype -->
              <template v-else-if="column.prop === 'product_subtype_id'" #default="{ row }">
                <el-select class="select-primary"
                           size="large"
                           v-model="row.product_subtype_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.subproduct_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </template>

              <!-- Ad Size -->
              <template v-else-if="column.prop === 'adsize_id'" #default="{ row }">
                <el-select class="select-primary"
                           size="large"
                           v-model="row.adsize_id"
                           placeholder="Required *">
                      <el-option v-for="option in store.mmData.adsize_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </template>
            </el-table-column>


            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              min-width="220"
              label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" @click="handleEdit(props.$index, props.row)">
                  RESET
                </p-button>
                <p-button type="danger" size="sm" @click="handleDelete(props.$index, props.row)">
                  IGNORE
                </p-button>
              </template>
            </el-table-column>


          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { unmappedCols, unmappedSearch } from './MundialColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    components: {
      PPagination
    },
    methods: {
      sanitizeMapping (rows) {
        const rowLen = rows.length;
        let mapArr = [];

        for (let i = 0; i < rowLen; i++) {
          const tmpRow = rows[i];
          let mapBool = true;

          // Site
          if (!tmpRow.sn_id) mapBool = false;

          // Device Type
          if (!tmpRow.device_type_id) mapBool = false;

          // Site
          if (!tmpRow.product_subtype_id) mapBool = false;

          // Site
          if (!tmpRow.adsize_id) mapBool = false;

          // MAP
          console.log({tmpRow, mapBool})

          if (mapBool) {
            const tmpDateTime = (new Date()).toISOString().split('T'),
            tmpDate = tmpDateTime[0],
            tmpTime = tmpDateTime[1].split('.')[0],
            cleanDateTime = [tmpDate, tmpTime].join(' ');

            tmpRow.inter = tmpRow.inter ? 1 : 0
            tmpRow.cdatetime = cleanDateTime
            tmpRow.udatetime = cleanDateTime
 
            mapArr.push(tmpRow)
          }
        }

        return mapArr
      },
      async createMap () {
        const tableData = JSON.parse(JSON.stringify(this.tableData)),
        mapData = await this.sanitizeMapping(tableData);
        
        console.log({mapData})

        store.createMundialMap(mapData);
      },
      handleEdit (index, row) {
        //const tmpRow = store.mmData.unmapped_list[index];
        const tmpRow = this.queriedData[index];
        tmpRow.sn_id = ''
        tmpRow.device_type_id = ''
        tmpRow.product_subtype_id = ''
        tmpRow.adsize_id = ''

        console.log({index, row, tmpRow}) 
      },
      async handleDelete (index, row) {
        console.log({index, row})
        if (confirm(`Are you sure you want to ignore ${row.adunit}? This can be undone in Ad Units > Manage Ignored.`)) {
          const boolCheck = await store.createMundialIgnored({adunit: row.adunit, cdate: (new Date()).toISOString().split('T')[0]})

          if (boolCheck === 'EXISTING_IGNORED') {
              alert('AD UNIT already exists!')
          } else if (boolCheck) {
              this.toggleCreateIgnored()
          } else {
              alert('AD UNIT is incorrect!')
          }
        }
      },
    },
    computed: {
      tableColumns () {
        console.log({unmappedCols})
        return unmappedCols
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = unmappedSearch,
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        const mundialData = JSON.parse(JSON.stringify(store.mmData.unmapped_list)),
        metricArr = ['platform_id','inter','counter'],
        metricSet = new Set(metricArr);

        return store.sanitizeMetrics(mundialData, metricSet, false)
      },
      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (store.mmData) {
          //cleanRows = store.mmData.unmapped_list
          cleanRows = this.generateSummary
        }
        console.log(store.mmData.unmapped_list)
        console.log({cleanRows})
        return cleanRows;
      },
    },
    data () {
      return {
        store,
        MUNDIAL_PLATFORM,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
        mapDict: {},
      }
    },
    mounted () {
      // Generate naming dictionary
      const tableColumns = unmappedCols;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Populate mapRow form fields
      //const unmapped_list = JSON.parse(JSON.stringify(store.mmData.unmapped_list));
      //for (let u = 0; u < unmapped_list.length; u++) {
        //const tmpRow = unmapped_list[u], 
        //tmpId = tmpRow.id, tmpInter = tmpRow.inter,
        //tmpAdUnit = tmpRow.adunit;
        
        //this.mapDict[tmpId] = {id:tmpId, adunit:tmpAdUnit, sn_id:tmpAdUnit, device_type_id:'', product_subtype_id:'', adsize_id:'', inter:tmpInter}
      //}

      // Log to console
      console.log({store, mapDict:this.mapDict})
    },
  }
</script>
<style>
  .mmWarning {
    color: #ef8157;
  }
  #exportMM {
    color: black;
  }
  .search-mm {
    margin-top: 25px;
  }
  .body-mm {
    padding-top: 0px !important;
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>
