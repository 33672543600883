<template>
  <div>


    <!-- Filters -->

    <div v-if="store.irisData" class="row availsHeader">
      <div v-if="!store.irisData.user.display_tc" class="col-md-4 availsColumn">
        <div class="form-group">
          <fg-input label="Inventory Forecasting Range" v-if="store.irisData" @change="setAvailsData()">
            <br>
            <p-radio inline v-model="store.irisAvailsRange" label='7' @click="setAvailsData()">7 Days</p-radio>
            <p-radio inline v-model="store.irisAvailsRange" label='30' @click="setAvailsData()">30 Days</p-radio>
          </fg-input>




          <!-- Keyword Search -->
          <fg-input type="text"
                  label="Keywords"
                  placeholder="Key1, Key2, ... KeyX"
                  v-model="store.keywordsInput">
          </fg-input>
          <!-- END Keyword -->


          <fg-input label="Segment" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.segmentSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.segment_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="Sub-Product" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.productSubtypeSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.subproduct_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="Demographics" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.demoSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.demo_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="Language" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.languageSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.language_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="Platform Type" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.deviceSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.device_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="DMA" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.dmaSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.dma_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="Gender" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.genderSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.gender_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <fg-input label="Age" v-if="store.irisData">
            <el-select class="select-default iris-default"
                       multiple
                       size="large"
                       v-model="store.ageSelected"
                       placeholder="All">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(store.irisData.age_options)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
            </el-select>
          </fg-input>
          <div class="text-center">
            <button @click="setAvailsData()" class="btn btn-info btn-fill btn-wd btn-iris-avails">
              Get Avails
            </button>

            <!-- OG buttons w/ required functionality
            <button @click="setAvailsData()" class="btn btn-info btn-fill btn-wd btn-iris-avails" v-if="store.segmentSelected.length || store.keywordsInput.length">
              Get Avails
            </button>
            <button disabled class="btn btn-info btn-fill btn-wd btn-iris-avails" v-else>
              Get Avails
            </button>
            -->

            <button @click="resetAvails()" class="btn btn-default btn-fill btn-wd btn-iris-reset">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-2 availsBorders">
        <!-- EMPTY -->
      </div>
      <div v-if="!store.irisData.user.display_tc" class="col-md-6 availsColumn">
        <!-- Reference -->
        <div class="row" v-if="availsData" v-for="avails in availsData">
          <div class="col-md-2">
              <!-- EMPTY -->
          </div>
          <div class="col-md-8">

            <!-- NEW -->
            <stats-card :type="avails.avails_projection > 650000 ? 'success' : avails.avails_projection < 250000 ? 'info' : 'warning'"
                    icon="nc-icon nc-globe"
                    :small-title="`${avails.avails_range}-Day Inventory Forecasting`"
                    :title="String(avails.avails_projection).replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
              <div class="stats" slot="footer">
                Segments
                <ul class="availsList" v-if="segmentFlag && segmentFlag.length && !segmentFlag.includes('all')">
                  <li v-for="s in avails.segment_id.split(',')">{{segmentDict[s]}}</li>
                </ul>

                Sub-Products
                <ul class="availsList" v-if="productSubtypeFlag && productSubtypeFlag.length && !productSubtypeFlag.includes('all')">
                  <li v-for="sp in avails.product_subtype_id.split(',')">{{subProductDict[sp]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                Demographics
                <ul class="availsList" v-if="demoFlag && demoFlag.length && !demoFlag.includes('all')">
                  <li v-for="d in avails.vertical_id.split(',')">{{demoDict[d]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                Language
                <ul class="availsList" v-if="languageFlag && languageFlag.length && !languageFlag.includes('all')">
                  <li v-for="l in avails.lang_id.split(',')">{{langDict[l]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                Platform Type
                <ul class="availsList" v-if="deviceFlag && deviceFlag.length && !deviceFlag.includes('all')">
                  <li v-for="d in avails.platform_id.split(',')">{{deviceDict[d]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                DMA
                <ul class="availsList" v-if="dmaFlag && dmaFlag.length && !dmaFlag.includes('all')">
                  <li v-for="dma in avails.dma_id.split(',')">{{dmaDict[dma]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                Gender
                <ul class="availsList" v-if="genderFlag && genderFlag.length && !genderFlag.includes('all')">
                  <li v-for="g in avails.gender_id.split(',')">{{genderDict[g]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                Age
                <ul class="availsList" v-if="ageFlag && ageFlag.length && !ageFlag.includes('all')">
                  <li v-for="a in avails.age_id.split(',')">{{ageDict[a]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>

                <!-- Device
                <ul class="availsList" v-if="deviceFlag && deviceFlag.length && !deviceFlag.includes('all')">
                  <li v-for="d in avails.device_id.split(',')">{{deviceDict[d]}}</li>
                </ul>
                <ul class="availsList" v-else>
                  <li>All</li>
                </ul>
                END DEVICE -->

                Segment IDs
                <ul class="availsList" v-if="keywordFlag && keywordFlag.length">
                  <li>{{avails.kw_name}}</li>
                </ul>                
                <ul class="availsList" v-else>
                  <li>{{avails.segment_id}}</li>
                </ul>                
                <div class="text-center" v-if="keywordFlag && keywordFlag.length">
                  <button @click="requestSegments()" class="btn btn-info btn-fill btn-wd btn-iris-avails" v-if="reqSegFlag">
                    Request Custom Segments
                  </button>
                  <button disabled class="btn btn-info btn-fill btn-wd btn-iris-avails" v-else>
                    Request Custom Segments
                  </button>
                </div>
              </div>
            </stats-card>
            <!-- END NEW -->



            <!-- OG broken out by SEGMENT
            <stats-card :type="avails.avails_projection > 650000 ? 'success' : avails.avails_projection < 250000 ? 'info' : 'warning'"
                    :icon="iconDict[avails.segment_id]"
                    :small-title="segmentDict[avails.segment_id]"
                    :title="(avails.avails_projection).replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
              <div class="stats" slot="footer">
                <i class="nc-icon nc-calendar-60"></i>
                {{avails.avails_range}}-Day Inventory Forecasting
                <ul class="availsList">
                  <li v-for="sp in avails.product_subtype_id.split(',')">{{subProductDict[sp]}}</li>
                </ul>
              </div>
            </stats-card>
            END OG -->



          </div>
          <div class="col-md-2">
              <!-- EMPTY -->
          </div>
        </div>
        <!-- END -->

        <!-- RESULTS
        <div class="row" v-if="availsData" v-for="avails in availsData">
          {{ avails }}
        </div>
        END -->

      </div>
    </div>
  </div>
</template>
<script>
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import { Badge } from 'src/components/UIComponents'
  import { store } from 'src/store'

  export default {
    components: {
      StatsCard,
      ChartCard,
      Badge,
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        store,
        statsCards: [
          {
            type: 'info',
            icon: 'nc-icon nc-globe',
            title: 'Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'info',
            icon: 'nc-icon nc-tv-2',
            title: 'Monetizable Requests',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: 'Impressions',
            value: '',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: 'Revenue',
            value: '$',
            footerText: '',
            footerIcon: 'nc-icon nc-calendar-60'
          }
        ],
        iconDict: {
          1:'nc-icon nc-user-run',
          2:'nc-icon nc-sun-fog-29',
          3:'nc-icon nc-world-2',
          4:'nc-icon nc-note-03',
          5:'nc-icon nc-istanbul',
          6:'nc-icon nc-camera-compact',
          7:'nc-icon nc-shop',
          8:'nc-icon nc-paper',
          9:'nc-icon nc-send',
        },
        filters: [],
        reqSegFlag: true,
        availsData: false,
        segmentFlag: false,
        productSubtypeFlag: false,
        demoFlag: false,
        languageFlag: false,
        dmaFlag: false,
        genderFlag: false,
        ageFlag: false,
        deviceFlag: false,
        keywordFlag: false,
        segmentDict: {},
        subProductDict: {},
        demoDict: {},
        langDict: {},
        dmaDict: {},
        genderDict: {},
        ageDict: {},
        deviceDict: {},
        siteDict: {},
      }
    },
    methods: {
      requestSegments() {
        this.reqSegFlag = false

        alert('Request successfully submitted!')
      },
      async setAvailsData() {
        this.setFilters()

        //console.log({filters:this.filters})

        const availsFilters = this.filters, boolCheck = await this.store.irisAvails(availsFilters)
        
        //console.log({availsFilters, boolCheck})

        if (boolCheck && boolCheck.length) {
          this.reqSegFlag = true
          this.availsData = boolCheck
          this.segmentFlag = this.store.keywordsInput ? this.store.keywordsInput : this.store.segmentSelected
          this.productSubtypeFlag = this.store.productSubtypeSelected
          this.demoFlag = this.store.demoSelected
          this.languageFlag = this.store.languageSelected
          this.dmaFlag = this.store.dmaSelected
          this.genderFlag = this.store.genderSelected
          this.ageFlag = this.store.ageSelected
          this.deviceFlag = this.store.deviceSelected
          this.keywordFlag = this.store.keywordsInput	  
        } else {
          alert('No results! Please try another query')
          this.resetAvails()
        }
      },
      setFilters() {
        const irisAvailsRange = this.store.irisAvailsRange,
        segments = this.store.segmentSelected, productSubtypes = this.store.productSubtypeSelected,
        device = this.store.deviceSelected,
        dma = this.store.dmaSelected,
        //keywords = this.store.keywordsInput.length ? this.store.keywordsInput.toLowerCase().replace(/ /g,',').split(',').filter(x => x) : false,
        keywords = this.store.keywordsInput.length ? this.store.keywordsInput.toLowerCase().split(',').filter(x => x) : false,
        language = this.store.languageSelected, gender = this.store.genderSelected,
        age = this.store.ageSelected, demo = this.store.demoSelected;

        this.filters = []
        this.filters.push(['avails_range', irisAvailsRange])
        this.filters.push(['product_subtype_id', productSubtypes])
        this.filters.push(['vertical_id', demo])
        this.filters.push(['lang_id', language])
        this.filters.push(['dma_id', dma])
        this.filters.push(['gender_id', gender])
        this.filters.push(['age_id', age])
        this.filters.push(['platform_id', device])
        this.filters.push(['segment_id', segments])
        if (keywords) this.filters.push(['kw_name', keywords])
      },
      resetAvails() {
        this.filters = []
        this.reqSegFlag = true
        this.availsData = false
        this.segmentFlag = false
        this.productSubtypeFlag = false
        this.demoFlag = false
        this.languageFlag = false
        this.dmaFlag = false
        this.genderFlag = false
        this.ageFlag = false
        this.deviceFlag = false
	this.keywordFlag = false

        this.store.irisAvailsRange = '7'
        this.store.segmentSelected = ''
        this.store.productSubtypeSelected = ''
        this.store.demoSelected = ''
        this.store.languageSelected = ''
        this.store.dmaSelected = ''
        this.store.genderSelected = ''
        this.store.ageSelected = ''
        this.store.deviceSelected = ''
	this.store.keywordsInput = ''
      },
    },
    mounted () {
      //
      // TEMP TEST
      //
      store.exportMundialTemplate()

      console.log({store:this.store})

      const segmentOpts = this.store.irisData.segment_options,
      subProductOpts = this.store.irisData.subproduct_options,
      demoOpts = this.store.irisData.demo_options,
      languageOpts = this.store.irisData.language_options,
      dmaOpts = this.store.irisData.dma_options,
      genderOpts = this.store.irisData.gender_options,
      ageOpts = this.store.irisData.age_options,
      deviceOpts = this.store.irisData.device_options;

      // Segments
      for (let s = 0; s < segmentOpts.length; s++) {
        const tmpSegment = segmentOpts[s],
        tmpKey = tmpSegment.value, tmpVal = tmpSegment.label;

        this.segmentDict[tmpKey] = tmpVal
      }
      // Sub-Products
      for (let sp = 0; sp < subProductOpts.length; sp++) {
        const tmpSubProduct = subProductOpts[sp],
        tmpKey = tmpSubProduct.value, tmpVal = tmpSubProduct.label;

        this.subProductDict[tmpKey] = tmpVal
      }
      // Demo
      for (let dm = 0; dm < demoOpts.length; dm++) {
        const tmpDemo = demoOpts[dm],
        tmpKey = tmpDemo.value, tmpVal = tmpDemo.label;

        this.demoDict[tmpKey] = tmpVal
      }
      // Language
      for (let l = 0; l < languageOpts.length; l++) {
        const tmpLang = languageOpts[l],
        tmpKey = tmpLang.value, tmpVal = tmpLang.label;

        this.langDict[tmpKey] = tmpVal
      }
      // DMA
      for (let dma = 0; dma < dmaOpts.length; dma++) {
        const tmpDMA = dmaOpts[dma],
        tmpKey = tmpDMA.value, tmpVal = tmpDMA.label;

        this.dmaDict[tmpKey] = tmpVal
      }
      // Gender
      for (let g = 0; g < genderOpts.length; g++) {
        const tmpGender = genderOpts[g],
        tmpKey = tmpGender.value, tmpVal = tmpGender.label;

        this.genderDict[tmpKey] = tmpVal
      }
      // Age
      for (let a = 0; a < ageOpts.length; a++) {
        const tmpAge = ageOpts[a],
        tmpKey = tmpAge.value, tmpVal = tmpAge.label;

        this.ageDict[tmpKey] = tmpVal
      }

      // Device
      for (let d = 0; d < deviceOpts.length; d++) {
        const tmpDevice = deviceOpts[d],
        tmpKey = tmpDevice.value, tmpVal = tmpDevice.label;
        this.deviceDict[tmpKey] = tmpVal
      }
    }
  }
</script>
<style>
.availsBorders {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-right: 1px solid #ddd;
    min-height: calc(95vh - 123px);
}
.availsHeader {
    border-top: none;
    margin-top: -3em;
    padding-top: 0.8em;
}
.availsColumn {
    padding-top: 1em;
}
.availsColumn > .form-group {
    padding-top: 0.2em;
}
.availsList {
    text-align: left;
}
.btn-iris-reset:hover, .btn-iris-reset:focus {
    background-color: #66615b !important;
}
.btn-iris-avails:hover, .btn-iris-avails:focus {
    background-color: #51bcda !important;
}
</style>
